@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200;300;400;500;600;700&family=Material+Icons&display=swap');
@import "../node_modules/animate.css/animate.min";

$gsu-light-blue: rgb(203, 216, 240);
$gsu-blue: rgb(14, 64, 159);
$gsu-dark-blue: rgb(0, 51, 173);
$gsu-grey: rgb(238, 238, 238);
$gsu-red: red;
$gsu-green: green;

html,
body {
  height: 100%;
  margin: 0;
  font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  overscroll-behavior: none;
  background-color: rgba(245, 248, 253, 1);

  // fix scrolling issue on iOS/Safari
  // causes offscreen positioning for overlay
  // -webkit-transform: translate3d(0, 0, 0);
}

.btn,
.button {
  overflow: hidden;
  cursor: pointer;
  background-color: $gsu-blue;
  font-size: 1rem !important;
  font-weight: 500 !important;
  text-transform: uppercase;
  color: #fff;
  margin: 0.2rem;
  padding: 1.25rem 1.5rem;
  display: inline-block;
  border: 0px;
  user-select: none;

  &.small {
    margin: 0;
    padding: .5rem;
    text-transform: none;
  }

  &.full,
  &.button-full {
    width: calc(100% - 32px);
    display: block;
    text-align: center;
    margin: 0.4rem 0;
    margin-left: 16px;
    box-sizing: border-box;
  }

  &:hover {
    opacity: 0.7;
  }
}

a {
  text-decoration: none;
}

.popover-viewport {
  padding: 12px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 2px 0;
  color: #222;
  font-weight: normal;
  user-select: none;
}

:not(.notice-content, .subText) > {
  h1 {
    font-size: 60px;
    font-family: Lato;
    letter-spacing: 0px;
  }

  h2 {
    color: rgba(43, 43, 43, 1);
    font-family: Oswald;
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0px;
    margin-bottom: 1rem;
  }

  h3 {
    color: rgba(43, 43, 43, 1);
    font-family: Lato;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0px;
  }

  h4 {
    color: rgba(43, 43, 43, 1);
    font-family: Lato;
    font-size: 20px;
    letter-spacing: 0px;
  }

  h5 {
    font-size: 1rem;
    font-weight: bold;
  }

  a,
  p,
  b,
  strong,
  span,
  div {
    font-size: 1rem;
  }
}

.red-font {
  color: $gsu-red;
}

.black-font {
  color: black;
}

.blue-font {
  color: $gsu-blue;
}

.green-font {
  color: $gsu-green;
}

.alert-icon {
  @extend .red-font;
  font-size: 1em;
  vertical-align: middle;
}

.alert-icon-right {
  @extend .alert-icon;
  padding-left: 0.2em;
}

.alert-icon-right {
  @extend .alert-icon;
  padding-right: 0.2em;
}

.app-content {
  box-sizing: border-box;
  .material-icons {
    vertical-align: middle;
    font-size: 1.25em;
  }
}

a, .icon-link {
  cursor: pointer;
  margin: 0;
  padding: 0;
  color: $gsu-blue;
  font-family: Lato;
  font-size: 18px !important;
  letter-spacing: 0px;
  font-weight: bold;

  i {
    margin-left: .4rem;
    font-size: 28px !important;
  }

  &.dont-wrap {
    white-space: nowrap;
  }
}

.flx {
  display: flex;
  align-content: space-between;
  justify-content: space-between;

  &.col {
    flex-flow: column;
  }

  &.row {
    flex-flow: row;
  }

  &.spread {
    align-items: center;
    text-align: end;
    & > *:first-child {
      text-align: left;
    }
  }

  &.indent {
    & > *:first-child {
      margin-left: 0.6em;
    }
  }

  & > * {
    flex: 1 1 0px;

    &.grow {
      flex: 1 1 auto;
    }

    &.shrink {
      flex: 0 1 auto;
    }
  }
}

hr {
  height: 0;
  margin: 2em 0;
  border: none;
  border-top: 2px dotted #eee;

  &.clear {
    opacity: 0;
  }

  &.half {
    margin: 1em 0;
  }
}

ul.bill-payment {
  margin: 0.5em 0;
  list-style: none;
  padding: 0;
}

ul.items {
  margin: 0.5em 0;
  padding: 0;
  list-style: none;

  & > li {
    margin: 0;
    display: block;

    & + li {
      margin-top: 1em;
    }
  }
}

.panel {
  padding: 1rem;
  background: #fff;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-size: 1.4rem;
}

.loading {
  margin: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    display: block;
    font-size: 5vmax;

    font-weight: 600;
    text-align: center;
  }

  &-header {
    width: 100%;
    margin: 0;
  }
}

app-root {
  height: 100%;
  display: flex;
  flex-flow: column;

  .app-body {
    min-height: calc(100%);
    height: 100%;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    padding: 0 16px;
    flex-wrap: wrap;

    .body-content {
      box-sizing: border-box;
      flex: 3;

      & > * {
        &.app-header {
          h2 {
            font-weight: 600;
          }

          .logout-button {
            cursor: pointer;
            border: none;
            background: #eee;
            font-size: 1em;
            font-weight: 600;
            color: inherit;
            float: right;
          }
        }
      }
    }

    quick-links {
      flex: 1;
    }

    @media (max-width: 1280px) {
      display: block;
    }
  }
}

.mat-menu-panel {
  max-width: 100% !important;
  min-height: 0px !important;
}

.mat-menu-content {
  padding: 0 !important;

  .mat-menu-item {
    display: flex;
    flex-direction: row;

    mat-icon {
      margin-top: 12px;
    }
  }

  .spacer {
    flex: 1 1 auto;
  }
}

.mat-dialog-container {
  padding: 0px 16px !important;
  max-height: 90vh !important;
  max-width: 90vw !important;
}

app-clock-offset {
  flex: 0 1 auto;
}

.cdk-overlay-dark-backdrop {
  background: rgba(8, 26, 72, .81);
}
